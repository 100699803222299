.background {
    background-color: #070707;
    touch-action: pan-y;
}

.contentLoader {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .loadingBar {
    height: 20px;
    background: #f0f0f0;
    border-radius: 4px;
    animation: pulse 1.5s ease-in-out infinite;
    
    &:nth-child(2) {
      width: 80%;
      animation-delay: 0.2s;
    }
    
    &:nth-child(3) {
      width: 60%;
      animation-delay: 0.4s;
    }
  }
  
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.4; }
    100% { opacity: 1; }
  }