@import '../../../style/breakpoints.scss';

.Humanizer {
    padding-top: 64px;
    z-index: 10;
    position: relative;
    overflow: hidden;

    &__background {
        z-index: -2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__backgroundNoise {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;

        background-image: url('./assets/img/1st_screen.svg'), linear-gradient(110deg, #000000 0%, #161616 49%, #000000 100%);
        background-repeat: no-repeat, no-repeat;
        background-size: cover, 100% 100%;
        background-position: center, center;
        background-blend-mode: overlay, normal;

        @media (max-width: 767px) {
            display: none;
          }
    }

    &__backgroundGradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 20%;
        height: 100%;
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 1) 21%,
            rgba(0, 0, 0, 0.3617822128851541) 73%,
            rgba(0, 0, 0, 0) 89%
        );
    }

    &__backgroundGradient2 {
        position: absolute;
        top: 0;
        right: 0;
        width: 18%;
        height: 100%;
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.3617822128851541) 14%,
            rgba(0, 0, 0, 1) 100%
        );
    }

    &__content {
        position: relative;
        padding-top: 120px;
        padding-bottom: 120px;
    }

    &__positionWrapper {
        z-index: 999;
        position: relative;
    }

    &__vector1 {
        z-index: 2;
        top: 0;
        right: -240px;
        width: 505px;
        height: 549px;
        position: absolute;
        background-image: url('./assets/img/Vector.webp');
        background-size: cover;
        opacity: 0.1;
    }

    &__textContainer {
        max-width: 1040px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 41px;
    }

    &__title {
        margin-bottom: 25px;
        text-align: center;
    }

    &__titleTransparent {
        color: black;
        background-color: black;
        text-shadow:
            -1px -1px 0 white,
            1px -1px 0 white,
            -1px 1px 0 white,
            1px 1px 0 white;
    }

    &__description {
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: center;
        color: white;
    }

    &__input {
        margin-bottom: 40px;
    }

    &__bottomTitle {
        font-family: 'Inter';
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: center;
        color: rgba(248, 248, 248, 1);

        &_container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            width: 100%;
            button {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 4px 8px;
                gap: 4px;
                outline: none;
                background: none;
                border: 1px solid #A8AAB7;
                border-radius: 5px;
                color: #f8f8f8;
            }
        }
    }

    @media (max-width: $lg-min) {
        padding-top: 41px;
    }

    @media (max-width: $lg-min) {
        &__content {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &__bottomTitle {
            display: none;

            &_container {
                display: none;
            }
        }

        &__textContainer {
            margin-bottom: 80px;
        }
    }
}
